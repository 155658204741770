<template>
  <div class="container">
    <div class="header">
      <p>第{{ index }}题</p>

      <van-button
          round
          class="question-card"
          @click="visible = true"
      >
        答题卡
      </van-button>
    </div>

    <div class="questions">
      <div class="questionStem" ref="questionStem"/>

      <div v-show="!showMore && curQuestionData['答题方式'] === '单选'">
        <div class="answer" ref="userAnswer"/>
        <van-icon @click="handleShowMore" name="arrow-down"/>
      </div>
      <div v-show="showMore && curQuestionData['答题方式'] === '单选'">
        <div class="answer" ref="systemAnswer"/>
      </div>
    </div>

    <div class="step">
      <van-collapse v-model="activeAnalyze">
        <van-collapse-item
            v-for="(item, idx) in curQuestionData['分步']"
            :key="idx"
            :name="idx"
        >
          <template #title>
            <div class="stepTitle">
              <p>步骤{{ idx + 1 }}:</p>
              <div clsss="stepTitle__desc" v-html="renderStepTitle(item['步骤'])"></div>
            </div>
          </template>
          <div v-html="renderStep(item['解析'][0] || [])"></div>
        </van-collapse-item>
      </van-collapse>

      <div class="step__tools">
        <van-button
            class="openAllBtn"
            round
            @click="handleStepVisible"
        >
          {{showAllStep ? '收起步骤' : '展开步骤'}}
        </van-button>
        <van-button
            class="nextBtn"
            round
            @click="handleQuestionClick(index)"
            v-if="index !== questionNum"
        >
          下一题
        </van-button>
      </div>
    </div>

    <question-card
        :visible="visible"
        :question-num="questionNum"
        :right-nums="rightNums"
        :error-nums="errorNums"
        @close="handleClose"
        @onQuestionClick="handleQuestionClick"
    />
  </div>
</template>

<script type="application/ecmascript">
import { mapState } from 'vuex'
import wxSDKMixins from './mixins';
import QuestionCard from './component/QuestionCard';

export default {
  components: {QuestionCard},
  mixins: [wxSDKMixins],
  data () {
    return {
      activeAnalyze: [], // 展开的解析
      showAllStep: false, // 展开全部 or 收起全部

      visible: false,
      index: 1,
      showMore: false, // 展示更多
      recordObj: {},
      resultObj: {},
    }
  },
  computed: {
    ...mapState({
      activityQuestions: state => state.activityQuestions,
      activityMobile: state => state.activityMobile
    }),
    errorNums () {
      return Object.keys(this.resultObj).map((key, index) => {
        if (!this.resultObj[key]) {
          return index + 1
        }
      })
    },
    rightNums () {
      return Object.keys(this.resultObj).map((key, index) => {
        if (this.resultObj[key]) {
          return index + 1
        }
      })
    },
    questionNum () {
      return this.userAnswers.length
    },
    userAnswers () {
      return Object.keys(this.recordObj).map(key => {
        return key
      })
    },
    curQuestionData () {
      const key = this.userAnswers[ this.index - 1 ]
      const question = this.activityQuestions.find(item => item.idx === key);
      return question || {}
    }
  },
  methods: {
    init () {
      this.renderQuestion();
      if (this.showMore) {
        this.renderSystemAnswer();
      } else {
        this.renderUserAnswer();
      }

    },
    // 绘制题目
    renderQuestion () {
      const type = this.curQuestionData[ '答题方式' ]

      this.$refs.questionStem.innerHTML = ''
      this.curQuestionData[ '例题' ].map(item => {
        const dom = document.createElement('span');
        switch (type) {
          case '填空':
            dom.setAttribute('style', 'display: inline-block;');
            let str = item[ 1 ]
            const span = document.createElement('span');
            if (item[ 1 ].indexOf('冇') > 0) {
              str = item[ 1 ].replace('冇', '');
              span.classList.add('inputValue');
              const key = this.userAnswers[ this.index - 1 ]
              span.innerText = this.recordObj[ key ]

              // 验证值的对错
              const isRight = this.curQuestionData[ '解' ][ 0 ] === this.recordObj[ key ]
              if (isRight) {
                span.classList.add('right');
              } else {
                span.classList.add('error');
              }
            }

            this.$kx.render(str, dom, {
              displayMode: true,
            })
            this.$refs.questionStem.appendChild(dom);
            if (item[ 1 ].indexOf('冇') > 0) {
              this.$refs.questionStem.appendChild(span);
            }
            break;
          case '单选':
            dom.setAttribute('style', 'display: inline-block;')
            this.$kx.render(item[ 1 ], dom, {
              displayMode: true,
            })
            this.$refs.questionStem.appendChild(dom);
            break;
          default:
            break
        }
      });
    },

    renderSystemAnswer () {
      const type = this.curQuestionData[ '答题方式' ]
      if (type !== '单选') {
        return
      }
      this.$refs[ 'systemAnswer' ].innerHTML = ''
      this.curQuestionData[ '选项' ].map((item, questionIndex) => {
        const dom = document.createElement('div');
        this.$kx.render(item, dom, {
          displayMode: true,
        })
        dom.className = 'questionOption__item';
        const isRight = this.curQuestionData[ '解' ][ questionIndex ]
        if (isRight) {
          dom.classList.add('right');
        }
        const key = this.userAnswers[ this.index - 1 ]
        if (this.recordObj[ key ] === item && !isRight) {
          dom.classList.add('error');
        }
        this.$refs[ 'systemAnswer' ].appendChild(dom);
      });
    },
    renderUserAnswer () {
      const type = this.curQuestionData[ '答题方式' ]
      if (type !== '单选') {
        return
      }
      this.$refs[ 'userAnswer' ].innerHTML = ''
      const key = this.userAnswers[ this.index - 1 ]
      const dom = document.createElement('div');
      this.$kx.render(this.recordObj[ key ], dom, {
        displayMode: true,
      })
      dom.className = 'questionOption__item';
      if (this.resultObj[ key ]) {
        dom.classList.add('right');
      } else {
        dom.classList.add('error');
      }
      this.$refs[ 'userAnswer' ].appendChild(dom);
    },

    renderStepTitle (str) {
      return this.$kx.renderToString(str, {
        throwOnError: false
      })
    },
    // 步骤内容渲染
    renderStep (params) {
      let str = ''
      params.forEach(item => {
        str += this.$kx.renderToString(item[1], {
          throwOnError: false
        })
      })
      return str
    },

    handleClose () {
      this.visible = false
    },

    handleShowMore () {
      this.showMore = true
      this.renderSystemAnswer()
    },

    handleQuestionClick (index) {
      this.index = index + 1
      this.visible = false
      this.showMore = false
      this.activeAnalyze = []
      this.init()
    },

    // 展开/收起步骤
    handleStepVisible () {
      this.showAllStep = !this.showAllStep
      if (this.showAllStep) {
        const len = this.curQuestionData['分步'].length
        for (let i = 0; i < len; i++) {
          this.activeAnalyze.push(i)
        }
      } else {
        this.activeAnalyze = []
      }
    },

    async queryResult () {
      const { mobile } = this.$route.query
      if (!mobile) {
        this.$router.push('/activity');
      }
      const ret = await this.$api.activityGetResult({
        param: {
          username: mobile
        }
      });
      this.resultObj = JSON.parse(ret.exam_result)
      this.recordObj = JSON.parse(ret.exam_record)
    }
  },
  mounted () {
    const { index } = this.$route.query;
    if (index !== undefined) {
      this.index = parseInt(index)
    }
    this.queryResult()
    this.$store.commit('queryActivityQuestions');
    this.initShare()
    this.$nextTick(() => {
      setTimeout(() => {
        this.init()
      }, 1000)
    })
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: calc(100vh - 48px);
  padding: 13px 16px;
  background: #F7F8FB;

  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    > p {
      height: 24px;
      font-size: 14px;
      color: #606580;
      line-height: 24px;
    }

    .question-card {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #2A3155;
      line-height: 26px;
      height: 26px;
      background: #F7F8FB;
      border-radius: 13px;
      border: 1px solid #B9BFD3;
    }
  }
}

.questions {
  .questionStem {
    text-align: left;

    /deep/ .inputValue {
      font-size: 18px;
      font-weight: 500;
      &.right {
        color: #15C392;
      }

      &.error {
        color: #FF4236;
      }
    }
  }

  .answer {
    /deep/ .questionOption__item {
      width: 343px;
      padding: 8px 0px;
      font-size: 18px;
      margin: 0 auto 12px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #E6E8EF;
      text-align: center;

      &.right {
        border: 1px solid #15C392;
        color: #15C392;
      }

      &.error {
        border: 1px solid #FF4236;
        color: #FF4236;
      }
    }

    /deep/ .katex-display {
      margin: 0;
    }

    /deep/ .katex-html {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    /deep/ .active {
      border: 1px solid #415FD5;
      color: #415FD5;
    }
  }
}

.step {
  /deep/ .van-collapse-item__content {
    background: #EFF2F7;
    color: #6c6c6e;
  }
  &__tools {
    height: 72px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    background: white;
    .openAllBtn {
      width: 191px;
      height: 40px;
      background: #F9C200;
      line-height: 40px;
      color: #FFFFFF;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      margin-right: 16px;
    }
    .nextBtn {
      width: 104px;
      height: 40px;
      background: #415FD5;
      line-height: 40px;
      color: #FFFFFF;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
    }
  }
}
.stepTitle {
   display: flex;
  flex-flow: row;
  justify-content: start;
  align-items: center;
  > p {
    width: 60px;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #415FD5;
    line-height: 19px;
  }
  /deep/ .cjk_fallback {
    font-size: 12px !important;
    font-family: MicrosoftYaHei;
    color: #2A3155;
    line-height: 19px;
  }
}

</style>

